/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { jsx, Themed } from "theme-ui"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ArtItem = ({ image }) => {
  let matches = image.node.base.match(
    /(?<date>\d{4}-\d{2}-\d{2})-(?<name>[\w-]+)/
  )
  if (!matches) {
    matches = {
      groups: {
        name: "untitled",
        date: "unknown",
      },
    }
  }
  return (
    <div>
      <Img
        // sx={{ border: "2px solid #222" }}
        fluid={image.node.childImageSharp.fluid}
        alt={matches.groups.name} // only use section of the file extension with the filename
      />
      <h1 sx={{ fontSize: 1, fontWeight: 500, mb: 1 }}>
        {matches.groups.name.replace(/-/g, " ")}
      </h1>
      <p sx={{ fontSize: 1, mt: "-.125rem", color: "#888" }}>
        {matches.groups.date}
      </p>
    </div>
  )
}

const ArtPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "art" }
        }
        sort: { fields: name, order: DESC }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid(quality: 60) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Art" />
      <Themed.h1 sx={{ mb: 4 }}>Art</Themed.h1>
      <div
        sx={{
          display: `grid`,
          gridTemplateColumns: ["auto"],
          gridGap: [2, 3, 4, 5],
        }}
      >
        {data.allFile.edges.map(image => (
          <ArtItem image={image} />
        ))}
      </div>
    </Layout>
  )
}

export default ArtPage
